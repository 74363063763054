import $ from 'jQuery'
import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import Swiper, { Navigation, Pagination, Fade } from 'swiper';
import { LuminousGallery } from 'luminous-lightbox';
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

// ハンバーガーメニューをクリックでactiveを追加
const hamburgerMenuToggle = () => {
	document.querySelector('.hamburger').addEventListener('click', () => {
		document.querySelector('.hamburger').classList.toggle('is-active');
		document.querySelector('.nav-menu').classList.toggle('is-active');
		document.querySelector('.menuText').classList.toggle('is-active');
		document.querySelector('html').classList.toggle('oh');
	});
};

// スムーススクロール
const smoothScroll = () => {
	$('.js-smooth').on('click', function(ev) {
		// goToSection.pause();
		let navHeight = $('header').innerHeight() * 1.25;
		// prevent default click event
		ev.preventDefault();
		// get the scroll-to target
		let targetSection = $(this).attr('href');
		// get vertical offset of target
		let targetOffset = $(targetSection).offset().top;
		gsap.to(window, {
			duration: 2,
			scrollTo: {y: targetOffset - navHeight},
			ease: 'power4.out',
			onComplete: () => {

			},
		});

	});
};

const worksLightBox = () => {
	const galleryOpts = {
		arrowNavigation: true,
		// onChange: () => {},
	};
	const options = {
		namespace: null,
		sourceAttribute: "href",
		caption: null,
		openTrigger: "click",
		closeTrigger: "click",
		closeWithEscape: true,
		closeOnScroll: false,
		showCloseButton: false,
		appendToNode: document.body,
		appendToSelector: null,
		onOpen: null,
		onClose: null,
		includeImgixJSClass: false,
		injectBaseStyles: true
	};
	new LuminousGallery(document.querySelectorAll(".worksLightBoxItem"), galleryOpts, options);
};

$(() => {
	smoothScroll();
	hamburgerMenuToggle();
	worksLightBox();
	// displayScrollTop();
});

$(window).on('resize', () => {

});

window.addEventListener('DOMContentLoaded', () => {

});

//IE11のfixed要素ががたつくのを防ぐ
if (navigator.userAgent.match(/MSIE 10/i) ||
	navigator.userAgent.match(/Trident\/7\./) ||
	navigator.userAgent.match(/Edge\/12\./)) {
	$('body').on('mousewheel', function() {
		event.preventDefault();
		var wd = event.wheelDelta;
		var csp = window.pageYOffset;
		window.scrollTo(0, csp - wd);
	});
}
